@font-face {
  font-family: 'GretaArabicAR';
  /*a name to be used later*/
  src: url('../../public/fonts/GretaArabicAR/GretaArabicAR-LT-Thin.ttf');
  font-weight: 100;
  /*URL to font*/
}

@font-face {
  font-family: 'GretaArabicAR';
  /*a name to be used later*/
  src: url('../../public/fonts/GretaArabicAR/GretaArabicAR-LT-ExtraLight.ttf');
  font-weight: 200;
  /*URL to font*/
}

@font-face {
  font-family: 'GretaArabicAR';
  /*a name to be used later*/
  src: url('../../public/fonts/GretaArabicAR/GretaArabicAR-LT-Light.ttf');
  font-weight: 300;
  /*URL to font*/
}

@font-face {
  font-family: 'GretaArabicAR';
  /*a name to be used later*/
  src: url('../../public/fonts/GretaArabicAR/GretaArabicAR-LT-Regular.ttf');
  font-weight: 400;
  /*URL to font*/
}

@font-face {
  font-family: 'GretaArabicAR';
  /*a name to be used later*/
  src: url('../../public/fonts/GretaArabicAR/GretaArabicAR-LT-Medium.ttf');
  font-weight: 500;
  /*URL to font*/
}

@font-face {
  font-family: 'GretaArabicAR';
  /*a name to be used later*/
  src: url('../../public/fonts/GretaArabicAR/GretaArabicAR-LT-SemiBold.ttf');
  font-weight: 600;
  /*URL to font*/
}

@font-face {
  font-family: 'GretaArabicAR';
  /*a name to be used later*/
  src: url('../../public/fonts/GretaArabicAR/GretaArabicAR-LT-Bold.ttf');
  font-weight: 700;
  /*URL to font*/
}

@font-face {
  font-family: 'GretaArabicAR';
  /*a name to be used later*/
  src: url('../../public/fonts/GretaArabicAR/GretaArabicAR-LT-Heavy.ttf');
  font-weight: 800;
  /*URL to font*/
}

@font-face {
  font-family: 'GretaArabicAR';
  /*a name to be used later*/
  src: url('../../public/fonts/GretaArabicAR/GretaArabicAR-LT-Black.ttf');
  font-weight: 900;
  /*URL to font*/
}

html,
body {
  font-family: 'GretaArabicAR' !important;
}

.main_block {
  display: block !important;
  outline: none !important;
}

.slick-slider {
  user-select: auto !important;
}

/* ===== BEGIN OF CENTERMODE CAROUSEL CSS ===== */


/* ===== END OF CENTERMODE CAROUSEL CSS ===== */

/* HAMBURGUR MENU */

.burger {
  display: flex;
  outline: none;
  height: 20px;
  width: 15px;
  border: 0px;
  padding: 0px;
  background: transparent;
  transition: all 250ms ease-out;
  cursor: pointer;
}

.burger:before,
.burger:after {
  content: '';
  width: 15px;
  height: 2px;
  position: absolute;
  background: #1a1a1a;
  transition: all 250ms ease-out;
  will-change: transform;
}

.burger:before {
  transform: translateY(-3px);
}

.burger:after {
  transform: translateY(3px);
}

.active.burger:before {
  transform: translateY(0) rotate(45deg);
}

.active.burger:after {
  transform: translateY(0) rotate(-45deg);
}

.expand-card {
  max-height: 0;
  transition: all 0.4s ease;
}

.expand-width {
  width: calc(100vw / 3);
}

@media (max-width: 768px) {
  .expand-width {
    width: 80vw;
  }
}

.expand-card-container:hover .expand-card {
  max-height: 200px;
}

/* Button hover effect */
/* .primary-button {
  display: inline-block;
  color: #fff;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.primary-button:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10rem;
  z-index: -2;
}

.primary-button:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #B1DDF8;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;
}

.main-button:hover .primary-button:before {
  width: 100%;
} */

@media (min-width: 768px) {
  .latest-news .slick-list {
    margin-right: -24px !important;
  }
}

.centered-modal-carousel .swiper-slide-active {
  transform: scaleY(1);
  transition: all .1s ease-in-out;
}

.centered-modal-carousel .swiper-slide-active .modal-description {
  display: block;
}

.centered-modal-carousel .swiper-slide-prev .modal-description {
  display: none;
}

.centered-modal-carousel .swiper-slide-next .modal-description {
  display: none;
}


.centered-modal-carousel .swiper-slide-prev {
  transition: all .1s ease-in-out !important;
  transform: scaleY(0.8) !important;
  overflow: clip;
  border-radius: 16px;
  opacity: 0.5;
}

.centered-modal-carousel .swiper-slide-next {
  transition: all .1s ease-in-out !important;
  transform: scaleY(0.8) !important;
  overflow: clip;
  border-radius: 16px;
  opacity: 0.5;
}

.carousel-modal .slick-list {
  height: 100%;
}

.carousel-modal .slick-track {
  height: 100%;
}

.carousel-modal .slick-slide>div {
  height: 100%;
}

.centered-modal-carousel .slick-slide {
  transform: scaleY(0.6);
  transition: all ease-in-out .2s;
}

.centered-modal-carousel .slick-slide .item {
  transform: scaleY(1.67);
  opacity: 0.5;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr !important;
}

.centered-modal-carousel .slick-center .item {
  transform: scaleY(1) !important;
  opacity: 1 !important;
}

.centered-modal-carousel .slick-center {
  transform: scaleY(1) !important;
}

.centered-modal-carousel .slick-center .modal-description {
  display: block !important;
}

.centered-modal-carousel .slick-slide .modal-description {
  display: none;
}


.centered-modal-carousel .slick-slide>div {
  margin: 0 16px !important;
}

#layout-container[dir='rtl'] .latest-news .slick-slide,
#layout-container[dir='rtl'] .hero-banner .slick-slide {
  float: left;
}


.centered-carousel .swiper-slide-active {
  transform: scaleY(1);
  transition: all .1s ease-in-out;
}

.centered-carousel .swiper-slide-active .modal-description {
  display: block;
}

.centered-carousel .swiper-slide-prev .modal-description {
  display: none;
}

.centered-carousel .swiper-slide-next .modal-description {
  display: none;
}


.centered-carousel .swiper-slide-prev {
  transition: all .1s ease-in-out !important;
  transform: scaleY(0.8) !important;
  overflow: clip;
}

.centered-carousel .swiper-slide-next {
  transition: all .1s ease-in-out !important;
  transform: scaleY(0.8) !important;
  overflow: clip;
}


/* @media (max-width: 2000px) {
  .modal-description {
    padding: 0 300px;
  }
} */
/* 
#sdf * {
  direction: ltr !important;
} */


/* @media (max-width: 1440px) {
  .modal-description {
    padding: 0 300px;
  }
}

@media (max-width: 1280px) {
  .modal-description {
    padding: 0 200px;
  }
}

@media (max-width: 1024px) {
  .modal-description {
    padding: 0 100px;
  }
}

@media (max-width: 768px) {
  .modal-description {
    padding: 0 40px;
  }
}

@media (min-width: 2000px) {
  .modal-description {
    padding: 0 300px;
  }
} */


/* .centered-modal-carousel .slick-center {
  transform: scaleY(1);
  transition: all .1s ease-in-out;
} */

/* .centered-modal-carousel .slick-slide:not(.slick-center) .centered-modal-carousel-item {
  max-width: 800px !important;
  transition: all .1s ease-in-out !important;
  transform: scaleY(1.5) !important;
} */

.react-responsive-modal-modal {
  /* width: 90% !important; */
  max-width: 1000px !important;
  overflow: clip;
}

.react-responsive-modal-modal {
  /* margin: 10px !important;
  padding: 10px !important; */
  background-color: transparent !important;
}

.thumb-carousel .slick-slide {
  margin: 0 8px !important;
}

.thumb-carousel .slick-track {
  margin-left: unset !important;
  margin-right: auto !important;
}

.twohalf-slider .slick-list {
  margin: 0 -56px !important;
}

.clip-button {
  clip-path: polygon(0 0, 100% 4%, 100% 96%, 0% 100%);
}

.link-underline {
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%)
}

.select-dropdown::-webkit-scrollbar {
  width: 4px !important;
}

.select-dropdown::-webkit-scrollbar-track {
  border-left: 1px solid #DDD6D0 !important;
  border-right: 1px solid #DDD6D0 !important;
  background: white !important;
  border-radius: 3px !important;
}

.select-dropdown::-webkit-scrollbar-thumb {
  background: white !important;
  border-radius: 8px !important;
  width: 2px !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #D4111C;
}

::placeholder {
  color: #010101 !important;
  opacity: 1 !important;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #010101 !important;
}

@media (min-width: 1536px) {
  .leftContainer {
    max-width: calc(1536px + (100vw - 1536px)/2);
    margin-left: auto;
    padding-left: 24px;
  }

  #layout-container[dir='rtl'] .leftContainer {
    margin-right: auto;
    margin-left: initial;
    padding-left: 0;
    padding-right: 24px;
  }
}

@media (max-width: 1536px) {
  .leftContainer {
    max-width: calc(1440px + (100vw - 1440px)/2);
    margin-left: auto;
    padding-left: 24px;
  }

  #layout-container[dir='rtl'] .leftContainer {
    margin-right: auto;
    margin-left: initial;
    padding-left: 0;
    padding-right: 24px;
  }
}

@media (max-width: 1440px) {
  .leftContainer {
    max-width: calc(1280px + (100vw - 1280px)/2);
    margin-left: auto;
    padding-left: 24px;
  }

  #layout-container[dir='rtl'] .leftContainer {
    margin-right: auto;
    margin-left: initial;
    padding-left: 0;
    padding-right: 24px;
  }
}

@media (max-width: 1279px) {
  .leftContainer {
    max-width: calc(1024px + (100vw - 1024px)/2);
    margin-left: auto;
    padding-left: 24px;
  }

  #layout-container[dir='rtl'] .leftContainer {
    margin-right: auto;
    margin-left: initial;
    padding-left: 0;
    padding-right: 24px;
  }
}

@media (max-width: 1023px) {
  .leftContainer {
    max-width: calc(930px + (100vw - 930px)/2);
    margin-left: auto;
    padding-left: 24px;
  }

  #layout-container[dir='rtl'] .leftContainer {
    margin-right: auto;
    margin-left: initial;
    padding-left: 0;
    padding-right: 24px;
  }
}

@media (max-width: 929px) {
  .leftContainer {
    max-width: calc(820px + (100vw - 820px)/2);
    margin-left: auto;
    padding-left: 24px;
  }

  #layout-container[dir='rtl'] .leftContainer {
    margin-right: auto;
    margin-left: initial;
    padding-left: 0;
    padding-right: 24px;
  }
}

@media (max-width: 819px) {
  .leftContainer {
    max-width: calc(768px + (100vw - 768px)/2);
    margin-left: auto;
    padding-left: 24px;
  }

  #layout-container[dir='rtl'] .leftContainer {
    margin-right: auto;
    margin-left: initial;
    padding-left: 0;
    padding-right: 24px;
  }
}

@media (max-width: 767px) {
  .leftContainer {
    max-width: calc(640px + (100vw - 640px)/2);
    margin-left: auto;
    padding-left: 24px;
  }

  #layout-container[dir='rtl'] .leftContainer {
    margin-right: auto;
    margin-left: initial;
    padding-left: 0;
    padding-right: 24px;
  }
}

@media (max-width: 639px) {
  .leftContainer {
    max-width: calc(640px + (100vw - 640px)/2);
    margin-left: auto;
    padding-left: 24px;
  }

  #layout-container[dir='rtl'] .leftContainer {
    margin-right: auto;
    margin-left: initial;
    padding-left: 0;
    padding-right: 24px;
  }
}

/* Gallery Default */
.Gallery_Default_swiper_1 .swiper-pagination {
  height: 0px;
}

.react-multi-carousel-item {
  transform: scaleY(0.6);
  transition: all ease .3s;
}

.react-multi-carousel-item--active {
  transform: scaleY(1);
  transition: all ease .3s;
}

.react-multi-carousel-item .item {
  transform: scaleY(1.67);
}

.react-multi-carousel-item--active .item {
  transform: scaleY(1) !important;
}

/* header */
.overlay {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.swiper-pagination {
  display: none !important;
}

.react-datepicker-popper .react-datepicker{
  border: none;
  font-family: GretaArabicAR;
}

.react-datepicker-popper .react-datepicker__header{
    background-color: white;
    border: none;
}

.react-datepicker__triangle{
  display: none;
}

.react-datepicker__current-month{
  margin-top: 24px;
}

.react-datepicker__day-names{
  margin-top: 16px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  display: none;
}

.react-datepicker__navigation--next {
    background: url(./icons/arrow-right-dark.svg) no-repeat;
    margin-top: 24px;
}

.react-datepicker__navigation--previous {
    background: url(./icons/arrow-left-dark.svg) no-repeat;
    margin-top: 24px;
    left: 20px;
}

.react-datepicker__day--in-range{
  border-radius: 20px;
  background-color: #FF9297 !important;
  color: #fff !important;
}

.react-datepicker__day--today{
   border-radius: 50%;
   width: 31px;
   border: 2px solid #D4111C !important;
}

.react-datepicker__day--today:hover{
   border-radius: 20px;
   border: 2px solid #D4111C !important;
}

.react-datepicker__day--in-selecting-range, .react-datepicker__day--today{
    border-radius: 20px;
    background-color: #FF9297 !important;
    color: #fff;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #FF9297;
  border-radius: 20px !important;
}

.react-datepicker__day--today{
  border: 2px solid #D4111C !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #FF9297;
  border-radius: 20px !important;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background-color: #FF9297 !important;
    border-radius: 20px !important;

}

.react-datepicker__day--keyboard-selected{
  background-color: white;
  color: black;
}

.react-datepicker__day--range-start{
  border-radius: 20px;
  background-color:  #D4111C !important;
  color: #fff;
}

.react-datepicker__day--range-end{
  border-radius: 20px;
  background-color:  #D4111C !important;
  color: #fff;
}

.react-datepicker__day--selecting-range-end, .react-datepicker__day--selecting-range-start{
  border-radius: 20px !important;
  background-color:  #D4111C !important;
}

.react-datepicker-wrapper{
  width: 100%;
}

.accessibility {
  width: 331px;
  max-width: 96%;
  max-height: 95%;
  position: fixed;
  top: 32px;
  transition: 0.5s;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  .accessibility {
    top: 12px;
    right: 2%;
  }
}
.accessibility-tool__wrap .active{
  background-color: white;
}
.visible-a {
  top: 0;
  transition: top .4s ease-out;
}

.hidden-a {
  top: -130px;
  transition: top .4s ease-out;
}

@media (min-width: 1536px) {
  .interactive-width {
    width: calc((1536px - 96px) / 2);
  }
}

@media (max-width: 1536px) {
  .interactive-width {
    width: calc((1440px - 96px) / 2);
  }
}

@media (max-width: 1440px) {
  .interactive-width {
    width: calc((1280px - 96px) / 2);
  }
}

@media (max-width: 1280px) {
  .interactive-width {
    width: calc((1024px - 96px) / 2);
  }
}

@media (max-width: 1024px) {
  .interactive-width {
    width: calc((930px - 96px) / 2);
  }
}

@media (max-width: 930px) {
  .interactive-width {
    width: calc((820px - 96px) / 2);
  }
}

@media (max-width: 820px) {
  .interactive-width {
    width: calc((768px - 96px) / 2);
  }
}

@media (max-width: 767px) {
  .interactive-width {
    width: 100%;
  }
}

@media (max-width: 639px) {
  .interactive-width {
    width: 100%;
  }
}

body.fontSize .text-\[18px\]{
  font-size: 24px;
}
body.fontSize .text-\[16px\]{
  font-size: 22px;
}
body.fontSize .text-\[22px\]{
  font-size: 28px;
}
body.fontSize .text-\[26px\]{
  font-size: 32px;
}
body.fontSize .text-\[30px\]{
  font-size: 36px;
}
body.fontSize .text-\[32px\]{
  font-size: 38px;
}
body.fontSize .text-\[36px\]{
  font-size: 42px;
}
body.fontSize .text-sm{
  font-size: 20px;
}
body.fontSize .text-base{
  font-size: 22px;
}
body.fontSize .text-lg{
  font-size: 24px;
}
body.fontSize .text-xl{
  font-size: 26px;
}
body.fontSize .text-2xl{
  font-size: 30px;
}
body.fontSize .text-3xl{
  font-size: 36px;
}
body.fontSize .text-4xl{
  font-size: 42px;
}
body.fontSize .text-5xl{
  font-size: 54px;
}
body.fontSize .text-6xl{
  font-size: 66px;
}

body.fontSize .banner .text-\[18px\]{
  font-size: 30px;
}
body.fontSize .banner .text-\[16px\]{
  font-size: 28px;
}
body.fontSize .banner .text-\[22px\]{
  font-size: 34px;
}
body.fontSize .banner .text-\[26px\]{
  font-size: 38px;
}
body.fontSize .banner .text-\[30px\]{
  font-size: 42px;
}
body.fontSize .banner .text-\[32px\]{
  font-size: 44px;
}
body.fontSize .banner .text-\[36px\]{
  font-size: 48px;
}
body.fontSize .banner .text-sm{
  font-size: 26px;
}
body.fontSize .banner .text-base{
  font-size: 28px;
}
body.fontSize .banner .text-lg{
  font-size: 30px;
}
body.fontSize .banner .text-xl{
  font-size: 32px;
}
body.fontSize .banner .text-2xl{
  font-size: 36px;
}
body.fontSize .banner .text-3xl{
  font-size: 42px;
}
body.fontSize .banner .text-4xl{
  font-size: 48px;
}
body.fontSize .banner .text-5xl{
  font-size: 60px;
}
body.fontSize .banner .text-6xl{
  font-size: 72px;
}

body.contrast main,
body.contrast #myNav,
body.contrast footer{
  -webkit-filter: grayscale(100%) contrast(120%);
  filter: grayscale(100%) contrast(120%);
}

body.contrast header .group:hover .group-hover\:text-red-500{
  color: #000000;
}

body.contrast header .group:hover .group-hover\:bg-red-500{
  background-color: #000000;
}

body.contrast .text-red-500 {
  color: #000000;
}
body.contrast .bg-red-500 {
  background-color: #000000;
}
body.contrast .text-gray-100 {
  color: #FFFFFF;
}
body.contrast .bg-gray-100 {
  background-color: #FFFFFF;
}
body.contrast .text-sans-500 {
  color: #000000;
}
body.contrast .bg-sans-500 {
  background-color: #000000;
}
body.contrast .text-white {
  color: #F2F2F1;
}
body.contrast .bg-white {
  background-color: #F2F2F1;
}
body.contrast .hover\:bg-white:hover {
  background-color: rgb(215, 213, 213);
}
body.contrast .accessibility-tool__wrap .active {
  background-color: rgb(215, 213, 213);
}
body.contrast .text-black-300 {
  color: #000000;
}
body.contrast .bg-sans-500 .text-black-300 {
  color: white;
}
body.contrast .bg-black-300 {
  background-color: #000000;
}

body.contrast .tag.hover\:bg-red-500:hover{
  background-color: #000000;
}

body.dark {
  background-color: #0E0F10 !important;
  color: #FEFEFE !important;
}
body.dark .tag:hover img{
  -webkit-filter: invert(0), contrast(1.75);
  filter: invert(0);
}

body.dark img,
body.dark .bg-cover {
  -webkit-filter: invert(100%), contrast(1.75);
  filter: invert(100%);
}

body.dark img .text-black-300,
body.dark .bg-cover .text-black-300 {
  color: black;
}

body.dark img .group:hover .group-hover\:bg-red-500 ,
body.dark .bg-cover .group:hover .group-hover\:bg-red-500 {
  background-color: red !important;
}

body.dark img .group:hover .group-hover\:text-red-500 ,
body.dark .bg-cover .group:hover .group-hover\:text-red-500 {
  color: red !important;
}

body.dark .text-red-500 {
  color: #2BEEE3;
}
body.dark .bg-red-500 {
  background-color: #2BEEE3;
}
body.dark img .bg-red-500,
body.dark .bg-cover .bg-red-500 {
  background-color: #D4111C;
}
body.dark .group:hover .group-hover\:text-red-500{
  color: #2BEEE3;
}
body.dark .text-gray-100 {
  color: #0E0F10;
}
body.dark .bg-gray-100 {
  background-color: #0E0F10;
}
body.dark .bg-gray-400 {
  background-color: #22282C !important;
}
body.dark .text-gray-300 {
  color: #0E0F10;
}
body.dark .bg-gray-300 {
  background-color: #0E0F10;
}
body.dark .text-sans-500 {
  color: #43515E;
}
body.dark .bg-sans-500 {
  background-color: #43515E;
}
body.dark .bg-white {
  background-color: #000000;
}
body.dark .hover\:bg-white:hover {
  background-color: #000000;
}
body.dark .text-black-300 {
  color: #FEFEFE;
}
body.dark .bg-black-300 {
  background-color: #0E0F10;
}
body.dark .bg-\[\#F1F0EF\] {
  background-color: #0E0F10 !important;
}
body.dark .accessibility-tool__wrap .active {
  background-color: #000000 !important;
}

body.dark .group:hover .group-hover\:bg-red-500{
  background-color: #2BEEE3;
}

body.dark .stroke-black-300{
  stroke: white;
}

body.dark .hover\:stroke-red-500:hover {
  stroke: #2BEEE3;
}

body.dark .fill-black-300{
  fill: white;
}

body.dark .hover\:fill-red-500:hover {
  fill: #2BEEE3;
}

body.dark .border-red-500{
  border-color: #2BEEE3;
}

body.dark .hover\:bg-red-500:hover{
  background-color: #2BEEE3;
}

body.fontFamily #layout-container {
  font-family: 'Roboto';
}

body.cursor,  body.cursor .cursor-pointer, body.cursor .hover\:cursor-pointer, body.cursor button, body.cursor a{
  cursor: url('./icons/big-cursor.svg') 10 10, auto !important;
}


body.arabicAlignment {
  text-align: left;
}

body.arabicAlignment .text-start {
  text-align: end;
}

body.arabicAlignment .rtl\:text-right {
  text-align: left;
}

@media (min-width: 768px) {
  body.arabicAlignment .rtl\:pl-16 {
    padding-left: 0;
    padding-right: 64px;
  }
}

body.arabicAlignment .align-revrse {
  flex-direction: row-reverse;
}

body.englishAlignment {
  text-align: right;
}

body.englishAlignment .text-start {
  text-align: end;
}

body.englishAlignment .text-left {
  text-align: right;
}

@media (min-width: 768px) {
  body.englishAlignment .rtl\:pl-16 {
    padding-left: 64px;
    padding-right: 0;
  }
}

body.englishAlignment .align-revrse {
  flex-direction: row-reverse;
}

#layout-container {
  position: relative;
  min-height: 100vh;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Gallery_Default_swiper_2 .swiper-slide-prev .img-container{
  background-color: #000000;
}
.Gallery_Default_swiper_2 .swiper-slide-prev img{
  opacity: 0.5;
}

.Gallery_Default_swiper_2 .swiper-slide-next .img-container {
  background-color: #000000;
}
.Gallery_Default_swiper_2 .swiper-slide-next img{
  opacity: 0.5;
}


body:not(.exp) .show-only-expEditor{
  display: none;
}

:is([dir="rtl"] .rtl\:direction-rtl) {
  direction: rtl;
}